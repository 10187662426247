import React, { createContext, ReactNode, useEffect, useState } from "react"
import { ICarrinho, IClienteLogado, IStorageCarrinho, IStorageCliente, ITokens } from "../global/types";
import { api } from "../services/api";

type SiteContextType = {
  tokens: ITokens,
  cliente: IClienteLogado | undefined
  carrinho: ICarrinho[],
  saveTokens: (token: string, cliente: IClienteLogado) => void
  saveCarrinho: (cartShopping: ICarrinho[]) => void
  logout: () => void
  checkToken: (token: string) => Promise<boolean>
}

type ContextProps = {
  children: ReactNode
}

export const SiteContext = createContext({} as SiteContextType);

export function SiteContextProvider(props: ContextProps) {

  const [tokens, setTokens] = useState<ITokens>({} as ITokens);
  const [cliente, setCliente] = useState<IClienteLogado>({} as IClienteLogado);
  const [carrinho, setCarrinho] = useState<ICarrinho[]>([])

  const saveTokens = (token: string, cliente: IClienteLogado) => {
    setCliente(cliente)
    setTokens({ token })

    const lsStringify = JSON.stringify({
      tokens: { token },
      cliente
    });

    localStorage.setItem("artClient", lsStringify)
  }

  const saveCarrinho = (cartShopping: ICarrinho[]) => {
    setCarrinho(cartShopping)

    const lsStringify = JSON.stringify({
      carrinho: cartShopping
    })

    localStorage.setItem("artCartShopping", lsStringify)
  }

  const logout = () => {
    localStorage.removeItem("artClient")
    setTokens({} as ITokens)
    setCliente({} as IClienteLogado)
  }

  const checkToken = async (token: string): Promise<boolean> => {
    try {
      await api.post("clientes/verifica_token", { token });
      return true
    } catch (err: any) {
      return false
    }
  }

  useEffect(() => {
    const strgCliente = localStorage.getItem("artClient")
    const strgCarrinho = localStorage.getItem("artCartShopping")

    if (strgCliente) {
      const lsParseCliente = JSON.parse(strgCliente) as IStorageCliente

      setTokens(lsParseCliente.tokens);
      setCliente(lsParseCliente.cliente);
    }

    if (strgCarrinho) {
      const lsParseCarrinhp = JSON.parse(strgCarrinho) as IStorageCarrinho

      setCarrinho(lsParseCarrinhp.carrinho)
    }

  }, [])

  return (
    <SiteContext.Provider value={{
      tokens,
      cliente,
      carrinho,
      saveTokens,
      saveCarrinho,
      logout,
      checkToken
    }}>
      {props.children}
    </SiteContext.Provider>
  )
}