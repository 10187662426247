import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAdmContext } from "../../hooks/useAdmContext";
import logoPng from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faArrowRightFromBracket,
  faBook,
  faBuilding,
  faUnlock,
  faUser,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";

import "./style.css"

export function LayoutAdm() {
  const { logout, usuario } = useAdmContext();
  const { pathname } = useLocation();

  const handleLogout = () => {
    if (!window.confirm("Deseja mesmo sair da sua conta?"))
      return;

    logout();
  }

  const txtTitle = (path: string): string => {

    if (path.includes("/adm/usuarios"))
      return "Usuários";

    if (path.includes("/adm/cursos"))
      return "Cursos";

    if (path.includes("/adm/clientes"))
      return "Clientes";

    if (path.includes("/adm/empresa"))
      return "Empresa";

    return "Dashboard";
  }

  return (
    <div id="LayoutDashboard">

      <div className="menu">
        <div className="logo" style={{ backgroundImage: `url(${logoPng})` }} />

        <nav>

          <Link to="clientes" className={`navLink ${pathname.includes("/clientes") ? "selected" : ""}`}>
            <FontAwesomeIcon icon={faUser} style={{ fontSize: "18px" }} />
            <p>Clientes</p>
          </Link>

          <Link to="cursos" className={`navLink ${pathname.includes("/cursos") ? "selected" : ""}`}>
            <FontAwesomeIcon icon={faBook} style={{ fontSize: "18px" }} />
            <p>Cursos</p>
          </Link>

          <Link to="empresa" className={`navLink ${pathname.includes("/empresa") ? "selected" : ""}`}>
            <FontAwesomeIcon icon={faBuilding} style={{ fontSize: "18px" }} />
            <p>Empresa</p>
          </Link>

          <Link to="usuarios" className={`navLink ${pathname.includes("/usuarios") ? "selected" : ""}`}>
            <FontAwesomeIcon icon={faUnlock} style={{ fontSize: "18px" }} />
            <p>Usuários</p>
          </Link>

        </nav>

      </div>

      <div className="body">

        <div className="faixaTopo">
          <h3>
            <FontAwesomeIcon icon={faAngleRight} />
            {txtTitle(pathname)}
          </h3>
          <FontAwesomeIcon icon={faUserCircle} className="avatarIcon" />
          <div className="avatar">
            {usuario?.nome}
            <FontAwesomeIcon icon={faArrowRightFromBracket} onClick={() => handleLogout()} />
          </div>
        </div>

        <div className="content">
          <Outlet />
        </div>

      </div>

    </div>
  )
}