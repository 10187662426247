import React, { useCallback, useEffect, useState } from "react"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import { faCartShopping } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSiteContext } from "../../hooks/useSiteContext"
import logoPng from "../../assets/logo.png"
import compraSeguraPng from "../../assets/compra_segura.png"
import pagSeguroPng from "../../assets/pagseguro.png"

import "./style.css"
import { IEmpresa } from "../../global/types"
import { api } from "../../services/api"

export function LayoutSite() {
  const navigate = useNavigate()
  const { carrinho } = useSiteContext()
  const { pathname } = useLocation()

  const [qtdCarrinho, setQtdCarrinho] = useState(0)

  const [empresa, setEmpresa] = useState<IEmpresa>({} as IEmpresa)

  const checkMenu = (): boolean => {
    if (pathname.includes("/curso/"))
      return false

    if (pathname.includes("/carrinho"))
      return false

    if (pathname.includes("/lgnCliente"))
      return false

    if (pathname.includes("/cadCliente"))
      return false

    if (pathname.includes("/pgto"))
      return false

    return true
  }

  const loadEmpresa = useCallback(async () => {
    await api.get("empresa")
      .then(res => {
        if (!res.data)
          return

        setEmpresa(res.data)
      })
  }, [])

  useEffect(() => {
    let qtd = 0

    carrinho.forEach(item => {
      qtd += item.quantidade
    })

    setQtdCarrinho(qtd)
  }, [carrinho])

  useEffect(() => {
    loadEmpresa()
  }, [loadEmpresa])

  return (
    <div id="LayoutSite">

      <div className="context">
        <nav>
          <div
            className="logo"
            style={{ backgroundImage: `url(${logoPng})` }}
            onClick={() => navigate("/")}
          />

          <Link to="/" className={`navLink ${checkMenu() ? 'selected' : ''}`}>
            HOME
          </Link>

          <a href="https://www.regenerativa.art.br/" className="navLink">
            VOLTAR PARA O SITE
          </a>

          <button className="carrinho" onClick={() => { navigate("/carrinho") }}>
            <FontAwesomeIcon icon={faCartShopping} />
            ({qtdCarrinho})
          </button>
        </nav>

        <div className="content">
          <Outlet />
        </div>

        <div className="lytFooter">
          <div style={{ width: "100%" }}>

            {empresa.nome && empresa.nome.length > 0 && (
              <p>{empresa.nome}</p>
            )}

            {empresa.endereco && empresa.endereco.length > 0 && (
              <p>{empresa.endereco}</p>
            )}

            {empresa.email && empresa.email.length > 0 && empresa.telefone.length > 0 && (
              <p>{empresa.email} | {empresa.telefone}</p>
            )}

            {empresa.email && empresa.email.length > 0 && empresa.telefone.length <= 0 && (
              <p>{empresa.email}</p>
            )}

            {empresa.email && empresa.email.length <= 0 && empresa.telefone.length > 0 && (
              <p>{empresa.telefone}</p>
            )}

            {empresa.cnpj && empresa.cnpj.length > 0 && (
              <p>{empresa.cnpj}</p>
            )}

          </div>
          <img src={compraSeguraPng} />
          <img src={pagSeguroPng} />
        </div>

      </div>
    </div>
  )
}