import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AdmContextProvider } from './contexts/AdmContext'
import { LayoutAdm } from './components/LayoutAdm'
import { Home } from './pages/Home'
import { LoginAdm } from './pages/LoginAdm'
import { Usuarios } from './pages/Usuarios'
import { UsuariosForm } from './pages/UsuariosForm'
import { HomeSite } from './pages/HomeSite'
import { Cursos } from './pages/Cursos'
import { CursosForm } from './pages/CursosForm'
import { Clientes } from './pages/Clientes'
import { LayoutSite } from './components/LayoutSite'
import { CursoSite } from './pages/CursoSite'
import { SiteContextProvider } from './contexts/SiteContext'
import { Carrinho } from './pages/Carrinho'
import { LoginCliente } from './pages/LoginCliente'
import { CadCliente } from "./pages/CadCliente"
import { Pgto } from './pages/Pgto'
import { Empresa } from './pages/Empresa'

function App() {
  return (
    <BrowserRouter>

      <SiteContextProvider>
        <Routes>
          <Route path='/' element={<LayoutSite />}>

            <Route path='' element={<HomeSite />} />

            <Route path='/curso/:id' element={<CursoSite />} />

            <Route path='/cursos/:pg' element={<HomeSite />} />

            <Route path='/carrinho' element={<Carrinho />} />

            <Route path='/lgnCliente' element={<LoginCliente />} />

            <Route path='/cadCliente' element={<CadCliente />} />

            <Route path='/pgto' element={<Pgto />} />

          </Route>
        </Routes>
      </SiteContextProvider>

      <AdmContextProvider>
        <Routes>
          <Route path='login' element={<LoginAdm />} />

          <Route path='adm' element={<LayoutAdm />}>
            <Route path='' element={<Home />} />

            <Route path='usuarios' element={<Usuarios />} />
            <Route path='usuarios/novo' element={<UsuariosForm tipo='new' />} />
            <Route path='usuarios/:id' element={<UsuariosForm tipo='edit' />} />

            <Route path='cursos' element={<Cursos />} />
            <Route path='cursos/novo' element={<CursosForm tipo='new' />} />
            <Route path='cursos/:id' element={<CursosForm tipo='edit' />} />

            <Route path='clientes' element={<Clientes />} />
            <Route path='empresa' element={<Empresa />} />
          </Route>
        </Routes>
      </AdmContextProvider>


    </BrowserRouter>
  );
}

export default App;
