import { faCartShopping } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Load } from "../../components/Load"
import { ICarrinho, ICursos } from "../../global/types"
import { useSiteContext } from "../../hooks/useSiteContext"
import { api } from "../../services/api"
import { currencyFormatter } from "../../utils/currencyFormatter"
import { convertDateToBr, convertToDate } from "../../utils/dateProvider"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel"

import "./style.css"

type IParams = {
  id: string
}

export function CursoSite() {
  const navigate = useNavigate()
  const { carrinho, saveCarrinho } = useSiteContext()
  const { id } = useParams() as IParams

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [curso, setCurso] = useState<ICursos>({} as ICursos)
  const [images, setImages] = useState<string[]>([])
  const [qtdVendas, setQtdVendas] = useState(0)

  const loadCurso = useCallback(async (id: number) => {
    setLoading(true)
    setTxtLoading("Carregando informações do curso")

    await api.get(`cursos/${id}`)
      .then(async (res) => {
        if (!res.data)
          return

        setCurso(res.data)

        const reqQtdVendas = await api.get(`cursos/qtdVendas?id_curso=${id}`)
        if (reqQtdVendas.data)
          setQtdVendas(reqQtdVendas.data)

        document.title = res.data.nome

        loadImages(res.data)
      })
      .catch((err) => {
        if (err.response?.data?.msg)
          alert(err.response.data.msg)
        else
          alert("Erro ao carregar informações do Curso")
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })
  }, [])

  const loadImages = useCallback(async (c: ICursos) => {
    await api.get(`cursos/getImgs?id_curso=${c.id}`)
      .then(res => {
        if (!res.data)
          return

        setImages(res.data)
      })
      .catch(err => {
        if (err?.response?.data?.msg)
          alert(err.response.data.msg)
        else
          alert("Erro ao carregar images.")
      })
  }, [])

  const handleAddCarrinho = () => {
    let alreadyExistis: boolean = carrinho.filter(e => e.curso.id === curso.id).length > 0
    const newCarrinho: ICarrinho[] = carrinho.map(item => { return item })

    if (!alreadyExistis)
      newCarrinho.push({
        curso,
        quantidade: 1
      })

    saveCarrinho(newCarrinho)
    navigate("/carrinho")
  }

  useEffect(() => {
    if (parseInt(id) > 0)
      loadCurso(parseInt(id))
  }, [id])

  return (
    <div id="pgCursoSite">
      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && curso.id && (
        <>
          <div className="painel info">

            <h1>{curso.nome}</h1>

            {curso.capa && curso.capa.length > 4 && (
              <img
                src={`${process.env.REACT_APP_BASE_URL}/archives/imgCursos/${curso.id}/${curso.capa}`}
                alt={curso.nome}
                className="capa"
              />
            )}

            <p>{curso.descricao}</p>

            <div className="imgsCarousel">
              <Carousel
                showThumbs={false}
                showArrows
                interval={3000}
                showStatus={false}
                autoPlay
                emulateTouch
                infiniteLoop
                autoFocus
                useKeyboardArrows
                dynamicHeight
              >
                {images.map(item => (
                  <div key={item}>
                    <img src={`${process.env.REACT_APP_BASE_URL}/archives/imgCursos/${curso.id}/${item}`} />
                  </div>
                ))}
              </Carousel>
            </div>

          </div>
          <div className="painel price">
            <h2>{currencyFormatter(parseFloat(`${curso.valor}`))}</h2>

            <p><b>Data de Início do Curso:</b> {convertDateToBr(convertToDate(curso.data_inicio))}</p>
            <p><b>Carga Horária:</b> {curso.carga_horaria} hrs</p>
            <p><b>Vagas Disponíveis:</b> {curso.maximo - qtdVendas}</p>

            <button onClick={() => { handleAddCarrinho() }}>
              <FontAwesomeIcon icon={faCartShopping} />
              COMPRAR
            </button>
          </div>
        </>
      )}

    </div>
  )
}