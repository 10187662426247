import { faBarcode, faCashRegister, faCreditCard } from "@fortawesome/free-solid-svg-icons"
import { faCreditCard as faDebitCard } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Load } from "../../components/Load"
import { IClientes } from "../../global/types"
import { useSiteContext } from "../../hooks/useSiteContext"
import { api } from "../../services/api"
import { currencyFormatter } from "../../utils/currencyFormatter"

import "./style.css"
import { Input } from "../../components/Input"

export function Pgto() {
  const navigate = useNavigate()
  const { carrinho, checkToken, tokens } = useSiteContext()

  const [cliente, setCliente] = useState<IClientes>({} as IClientes)

  const [overlay, setOverlay] = useState(false)
  const [loading, setLoading] = useState(true)
  const [txtLoading, setTxtLoading] = useState("Verificando login do cliente...")

  const [total, setTotal] = useState(0)

  const [paymentMethod, setPaymentMethod] = useState<undefined | "BOLETO" | "CREDIT_CARD" | "DEBIT_CARD">()

  const [cardNumber, setCardNumber] = useState("")
  const [expMonth, setExpMonth] = useState("")
  const [expYear, setExpYear] = useState("")
  const [securityCode, setSecurityCode] = useState("")
  const [holderName, setHolderName] = useState("")

  const verificaLogin = useCallback(async (token: string) => {

    try {
      const tokenChecking = await checkToken(token)

      if (!tokenChecking)
        throw new Error("Erro ao recuperar dados do login");

      setTxtLoading("Recuperando dados do cliente...")

      const idCliente: number = parseInt(token.split(".")[0])
      const reqCliente = await api.get(`clientes/${idCliente}`)

      if (!reqCliente.data)
        throw new Error("Erro ao recuperar dados do Cliente");

      setCliente(reqCliente.data)

      setLoading(false)
      setTxtLoading("")

    } catch (err: any) {
      if (err.response?.data?.msg)
        alert(err.response.data.msg)
      else
        alert("Erro ao recuperar dados do login")

      navigate("/lgnCliente")
    }
  }, [])

  const handleEnviarPgto = async () => {
    if (!paymentMethod)
      return

    if (
      paymentMethod !== "BOLETO" &&
      (
        cardNumber.length < 16 ||
        expMonth.length !== 2 ||
        expYear.length < 4 ||
        securityCode.length < 3 ||
        holderName.length < 5
      )
    )
      return alert("O preenchimento de todos os campos é obrigatório")

    try {
      setLoading(true)
      setOverlay(true)

      await Promise.all(
        carrinho.map(async (item, i) => {
          setTxtLoading(`Verificando disponibilidade ${i + 1}/${carrinho.length}...`)
          const reqQtdVendas = await api.get(`cursos/qtdVendas?id_curso=${item.curso.id}`)

          if (!reqQtdVendas.data)
            throw new Error(`Erro ao recuperar vendas do curso "${item.curso.nome}"`);

          const qtdVendas: number = reqQtdVendas.data

          const qtdCompra: number = item.quantidade
          const qtdDisponivel: number = item.curso.maximo - qtdVendas

          if (qtdVendas >= item.curso.maximo || qtdCompra > qtdDisponivel)
            if (carrinho.length > 1)
              throw new Error(`Venda máxima atingida do curso "${item.curso.nome}". Remova o curso de seu carrinho para processar o pagamento dos demais cursos`);
            else
              throw new Error(`Venda máxima atingida do curso "${item.curso.nome}".`);

        })
      )

      setTxtLoading("Enviando dados de pagamento...")

      await setTimeout(() => {
        setLoading(false)
        setOverlay(false)
        setTxtLoading("")
      }, 2000);

    } catch (err: any) {
      if (err.response?.data?.msg)
        alert(err.response.data.msg)
      else if (err.message)
        alert(err.message)
      else
        alert("Erro ao processar compra. Tente novamente mais tarde")

      setLoading(false)
      setOverlay(false)
      setTxtLoading("")
    }

  }

  useEffect(() => {
    if (tokens?.token)
      verificaLogin(tokens.token)
  }, [tokens, verificaLogin])

  useEffect(() => {
    let newTotal = 0

    carrinho.forEach(item => {
      newTotal += item.quantidade * parseFloat(`${item.curso.valor}`)
    })

    setTotal(newTotal)

  }, [carrinho])

  return (
    <div id="pgPgto">

      {overlay && (
        <div className="overlayPgto" />
      )}

      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && (
        <>
          <div className="painel carrinho">
            {carrinho.map((item, i) => (
              <div key={`c-${i}`}>
                <p className="qtd">{item.quantidade}x</p>
                <p className="nome">{item.curso.nome}x</p>
                <p className="valor">{currencyFormatter(parseFloat(`${item.curso.valor}`) * item.quantidade)}</p>
              </div>
            ))}
            <h4>Total: {currencyFormatter(total)}</h4>
          </div>

          <div className="painel cliente">
            <p><b>Cliente:</b> {cliente.nome}</p>
            <p><b>CPF:</b> {cliente.cpf}</p>
            <p><b>Data Nascimento:</b> {cliente.data_nascimento}</p>
            <p><b>E-mail:</b> {cliente.email}</p>
          </div>

          <div className="paymentContent">
            <div className="painel methodPay">
              <button className={`${paymentMethod && paymentMethod === "CREDIT_CARD" ? "selected" : ""}`} onClick={() => setPaymentMethod("CREDIT_CARD")}>
                <FontAwesomeIcon icon={faCreditCard} />
                CARTÃO DE CRÉDITO
              </button>

              <button className={`${paymentMethod && paymentMethod === "DEBIT_CARD" ? "selected" : ""}`} onClick={() => setPaymentMethod("DEBIT_CARD")}>
                <FontAwesomeIcon icon={faDebitCard} />
                CARTÃO DE DÉBITO
              </button>

              <button className={`${paymentMethod && paymentMethod === "BOLETO" ? "selected" : ""}`} onClick={() => setPaymentMethod("BOLETO")}>
                <FontAwesomeIcon icon={faBarcode} />
                BOLETO
              </button>
            </div>

            <div className="painel formPay">

              {!paymentMethod && (
                <h3>SELECIONE UMA FORMA DE PAGAMENTO</h3>
              )}

              {paymentMethod && paymentMethod !== "BOLETO" && (
                <>
                  <label>Número do Cartão:</label>
                  <Input
                    mask="credit_card"
                    value={cardNumber}
                    inputMaskChange={(v) => setCardNumber(v)}
                    placeholder="Número do Cartão"
                    maxLength={19}
                    type="tel"
                  />

                  <label>Nome no Cartão:</label>
                  <Input
                    mask="none"
                    value={holderName}
                    inputMaskChange={(v) => setHolderName(v)}
                    placeholder="Nome do Cartão"
                  />

                  <label>Vencimento:</label>
                  <div className="flexRow">
                    <Input
                      mask="number"
                      value={expMonth}
                      inputMaskChange={(v) => setExpMonth(v)}
                      placeholder="Mês"
                      type="tel"
                      maxLength={2}
                    />
                    <Input
                      mask="number"
                      value={expYear}
                      inputMaskChange={(v) => setExpYear(v)}
                      placeholder="Ano"
                      type="tel"
                      maxLength={5}
                    />
                  </div>

                  <label>Código de Segurança:</label>
                  <Input
                    mask="none"
                    value={securityCode}
                    inputMaskChange={(v) => setSecurityCode(v)}
                    placeholder="Código de Segurança"
                  />
                </>
              )}

              {paymentMethod && (
                <button onClick={() => handleEnviarPgto()}>
                  <FontAwesomeIcon icon={faCashRegister} />
                  ENVIAR PAGAMENTO
                </button>
              )}

            </div>
          </div>
        </>
      )}

    </div>
  )
}