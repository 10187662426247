import React from "react"
import DatePicker, { DatePickerProps } from "react-date-picker"
import "./style.css"

type Props = DatePickerProps

export function InputDate({ ...rest }: Props) {

  return (
    <div id="dvDatePicker">
      <DatePicker
        className="customInputDate"
        format="dd/MM/y"
        {...rest}
      />
    </div>
  )
}